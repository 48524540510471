.week-event-container {
  height: 100%;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;

  &.appointment {
    background-color: #e3f2fd;
    border: solid 1px #2196f3;
  }

  &.request {
    background-color: #fffbee;
    border: solid 1px #fbc02d;
  }

  &.past {
    background-color: #e8f5e9;
    border: solid 1px #4caf50;
  }

  .week-event {
    font-size: 11px;

    .week-event-name {
      color: black;
      padding-bottom: 4px;
    }

    .week-event-time {
      color: #757575;
    }
  }
}
