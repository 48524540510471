.agenda-date-container {
  display: flex;
  // width: 72px;
  height: 70px;
  border-bottom: 1px solid black;
  .agenda-date-content {
    display: block;
    .month-with-number {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    .day-text {
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
    }
  }
}
