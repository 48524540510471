.calendar-section {
  .show-more {
    color: blue;
  }

  .myCustomHeight {
    height: 800px;

    .rbc-calendar {
      height: fit-content;
      .rbc-month-view {
        .rbc-row.rbc-month-header {
          height: 24px;
          .rbc-header {
            font-weight: 400;
            font-size: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .rbc-month-row {
          flex: initial;
          flex-basis: auto;
          height: 82px;
          .rbc-row-bg {
            height: 82px;

            .rbc-day-bg {
              &.rbc-today {
                background-color: transparent;
              }
            }
          }
          .rbc-row-content {
            .rbc-row {
              .rbc-date-cell {
                display: flex;
                justify-content: center;
                padding-top: 4px;
                font-size: 12px;

                &.rbc-now {
                  .rbc-button-link {
                    width: 24px;
                    height: 20px;
                    background-color: black;
                    color: white;
                    border-radius: 4px;
                  }
                }
              }

              .rbc-row-segment {
                .rbc-event {
                  background-color: transparent;
                  padding: 0px 2px;
                  .rbc-event-content {
                    color: transparent;
                    height: 100%;
                  }

                  div.rbc-event-content[title='Niche Appointment'] {
                    display: none;
                  }
                }

                .rbc-button-link.rbc-show-more {
                  color: #001cff;
                  margin-top: 4px;
                  position: relative;
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .rbc-time-view {
        .rbc-time-header {
          .rbc-time-header-content {
            border: 0px;
            .rbc-row.rbc-time-header-cell {
              .rbc-header {
                height: 48px;
                display: flex;
                justify-content: center;
                border-bottom: 0px;
                border-left: 0px;

                @media (max-width: 520px) {
                  font-size: 9px;
                }

                &.rbc-today {
                  background-color: transparent;

                  button {
                    width: 50px;
                    background: #000000;
                    border-radius: 8px;
                    color: white;
                    font-size: 15px;

                    span{
                        white-space: break-spaces;
                        display: inline-block;
                    }

                  }
                }

                button {
                    width: 40px;
                    font-size: 15px;

                    span{
                        white-space: break-spaces;
                        width: 30px;
                    }
                  }
              }
            }

            .rbc-allday-cell {
              height: 0px;
            }
          }
        }

        .rbc-time-content {
          .rbc-time-gutter.rbc-time-column {
            background: #f5f5f5;
            .rbc-timeslot-group {
              border-bottom: 0px;

              div {
                background-color: white !important;
              }
              .rbc-time-slot {
                display: flex;
                justify-content: center;
                align-items: end;
              }
            }
          }

          .rbc-day-slot.rbc-time-column {
            .rbc-timeslot-group {
              .rbc-time-slot {
                border-top: 0px;
              }
            }
          }

          .rbc-day-slot {
            .rbc-events-container {
              margin-right: 0px;
              .rbc-event {
                padding: 0px;
                white-space: nowrap;
              }
            }
          }
        }

        .rbc-day-slot.rbc-time-column {
          .rbc-events-container {
            .rbc-event {
              // display: none;
              background-color: transparent;
              border: 0px;

              .rbc-event-label {
                display: none;
              }
            }
          }
        }

        .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
          .rbc-events-container {
            .rbc-event {
              // display: none;
              background-color: transparent;
              border: 0px;

              .rbc-event-label {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
