.btn.btn-outline-primary {
  border-color: #001cff;
  color: #001cff;

  &:hover {
    background-color: #001cff;
    border-color: #001cff;
    color: white;
  }
}

.sign-in-out-button {
  margin: 0px 8px;
  color: #001cff;
  text-decoration: underline;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  width: 64px;
  height: 20px;
  cursor: pointer;
}
