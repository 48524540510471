@import url(https://fonts.googleapis.com/css?family=Montserrat);

.container {
  max-width: fit-content;
}
.profile-container {
  display: flex;
  justify-content: center;
  @media (max-width: 900px) {
    display: block;
  }

  .details-column {
    max-width: 384px;
    min-width: 384px;
    padding: 24px;
    // margin: 0 auto;
    font-family: 'Montserrat';

    @media (max-width: 900px) {
      padding: 24px 24px 0px 24px;
      margin: 0 auto;
      max-width: 608px;
      min-width: auto;
    }

    .profile-header {
      display: flex;
      margin-bottom: 16px;

      .profile-header-text {
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: -2%;
        width: 154px;
        text-align: left;
      }

      .btn {
        width: 88px;
        margin-right: 8px;
      }

      .btn.btn-outline-primary.btn-sm {
        color: #001cff;
        border-color: #001cff;
        margin-left: 8px;

        &:hover {
          color: white;
          background-color: #001cff;
        }
      }
    }

    .section-header {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      display: flex;
      margin-bottom: 8px;
    }

    .availability-pill {
      border: solid 1px;
      border-color: black;
      border-radius: 12px;
      text-align: center;
      width: 149px;
      height: 24px;
      font-size: 12px;
      padding: 2px;
    }

    .expert-profile-contents {
      border: solid 1px;
      border-color: #eeeeee;
      border-radius: 8px;
      padding: 24px;

      .user-details {
        display: block;

        .reviews {
          display: flex;
          margin-top: 18px;

          span {
            display: contents;
            font-size: 13px;
            color: #9e9e9e;
          }
        }

        .local-time {
          font-size: 13px;
          color: #9e9e9e;
          margin-top: 18px;
          display: flex;

          span {
            margin-right: 4px;
          }
        }
      }

      .divider {
        margin-top: 24px;
        margin-bottom: 24px;
        width: 100%;
        height: 1px;
        background-color: #eeeeee;
      }

      .bio {
        margin-bottom: 32px;

        .bio-text-frame {
          width: 296px;
          font-weight: 400;
          text-align: left;
          line-height: 26px;
          font-size: 13px;
          color: #212121;
        }
      }

      .phone-number {
        display: contents;
        .phone-number-number {
          font-size: 16px;
          color: #212121;
          display: flex;
          margin-bottom: 32px;

          span {
            margin-left: 8px;
            color: #2196f3;
          }

          #checkmark {
            margin-left: 5px;
            margin-top: -2px;
          }
        }
      }

      .websites {
        display: contents;
        .website {
          font-size: 16px;
          overflow: hidden;
          white-space: nowrap;
          margin-bottom: 8px;
          color: #212121;
          font-weight: 400;
          text-overflow: ellipsis;
          color: #001cff;
          text-decoration: underline;
          cursor: pointer;
          text-align: left;
        }
      }
    }

    .profile-picture {
      .profile-picture-frame {
        max-width: 380px;
        height: 300px;
        background-color: #fafafa;
        border-radius: 8px;
        border: solid;
        border-color: #eeeeee;
        margin-bottom: 16px;
        display: flex;

        @media (max-width: 900px) {
          max-width: 608px;
          background: #f0f9ff;
          height: fit-content;
        }

        .profile-picture-photo {
          width: 248px;
          height: 248px;
          margin: auto;

          img{
            border-radius: 16px;
          }

          @media (max-width: 900px) {
            height: 100%;
            margin: 16px auto;
          }
        }
      }
    }
  }

  .profile-tabs {
    max-width: 608px;
    width: 100%;
    @media (max-width: 900px) {
      padding: 0px 24px 0px 24px;
      margin: 0 auto;
    }
    .nav-tabs {
      margin-top: 36px;
      flex-wrap: nowrap;

      cursor: pointer;

      .nav-item {
        max-width: 136px;
        width: 100%;

        .nav-link {
          font-size: 14px;
          color: #757575;

          &.active {
            color: #000000;
          }
        }
      }
    }
  }
}
