@import url(https://fonts.googleapis.com/css?family=Montserrat);

.modal-backdrop {
  z-index: 1 !important;
}

.offcanvas-body {
  display: block;

  .offcanvas-menu-item {
    margin-bottom: 4px;
  }

  a {
    text-decoration: none;
    color: black;
    width: fit-content;
  }
}

.header-container {
  background-color: #f5faff;

  .header-contents {
    display: flex;
    height: 48px;
    align-items: center;
    max-width: 1320px;
    margin: 0 auto;
    
    .spinner-border {
      margin-top: 0px;
    }

    .col-5 {
      .header-logo {
        background-image: url('../../Assets/JPGs/wt-logo-horizontal.png');
        background-size: 150px;
        width: 150px;
        height: 28px;

        &:hover {
          cursor: pointer;
        }

        svg {
          height: 32px;
          width: 32px;
          margin-right: 4px;
        }
      }
    }

    .hamburger-menu {
      @media (min-width: 1070px) {
        display: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .header-links {
      .header-link {
        text-decoration: none !important;
        color: black;
        font-size: 16px;
        font-family: 'Montserrat';

        &:hover {
          cursor: pointer;
        }
      }

      @media (max-width: 1070px) {
        display: none;
      }
    }

    .separator {
      width: 1px;
    }

    .sign-in-buttons {
      font-family: 'Montserrat';
      font-size: 14px;
      align-items: center;
      display: flex;

      .expert-sign-up {
        margin-right: 8px;

        @media (max-width: 550px) {
          display: none;
        }
      }

      .log-in {
        margin-right: 8px;
        display: flex;
        align-items: center;

        img:before {
          background-image: url('../../Assets/SVGs/User.jpg');
        }
      }
    }

    .avatar-icon {
      color: #bdbdbd;

      svg {
        height: 32px;
        width: 32px;
      }

      .userImage {
        .user-picture {
          border-radius: 1rem;
        }

        .btn.btn-outline-dark.btn-sm {
          border-color: #e0e0e0;
          border-radius: 1.2rem;

          &:hover {
            background-color: #f5faff;
          }

          .dropdown-toggle.show {
            &:active {
              background-color: #f5faff;
            }
            background-color: #f5faff;
          }
        }

        .dropdown-menu {
          .dropdown-item {
            &:hover {
              background-color: white;
            }
            .btn.btn-outline-dark.btn-sm {
              &:hover {
                background-color: black;
              }
            }
          }
        }
      }
    }
  }
}
