.checklist-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: fixed;

    width: 336px;
    height: 320px;
    right: 24px;
    bottom: 24px;

    background: #FFFFFF;

    box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    .checklist-header{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        height: 40px;
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 16px;
        border-bottom: solid;
        border-color: #EEEEEE;

        span{
            position: absolute;
            right: 16px;
            color: #616161;

            .completed-num{
                color: black;
                display: contents;
            }
        }
    }

    .checklist-row{
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        display: flex;
        align-items: center;

        .checkmark{
            margin-left: 16px;
        }

        .row-text{
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;

            color: #4CAF50;

            margin-left: 8px;

            &.incomplete-row-text{
                color: #616161;
            }
        }

        .complete-status{
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            position: absolute;
            right: 16px;

            .done-status{
                color: #BDBDBD;
            }

            .go-now-status{
                text-decoration-line: underline;
                color: #001CFF;
                cursor: pointer;
            }
        }
    }

    .checklist-footer{
        height: 40px;
        display: flex;
        align-items: center;
        border-top: solid;
        border-color: #EEEEEE;
        width: 100%;
        .checklist-footer-text{
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            position: absolute;
            right: 16px;
            color: #616161;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}