@import url(https://fonts.googleapis.com/css?family=Montserrat);

.modal-dialog{
    // max-width: 640px !important;
    max-width: 640px;
    font-family: "Montserrat";
    padding: 24px;
    .modal-content{

        .modal-header{
            .modal-title{
                font-weight: 700;
                font-size: 20px;
            }
        }

        .modal-body{

            text-align: center;

            .modal-body-text{
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                text-align: left;
                padding-bottom: 20px;
                border-bottom: solid #E0E0E0;
            }

            .modal-availability-selection{
                .day-row{
                    display: flex;
                    width: 100%;
                    height: fit-content;
                    min-height: 64px;
                    border-bottom: solid #E0E0E0;

                    .unavailable{
                        width: 100%;
                        min-width: 64px;
                        justify-content: left;
                        display: flex;
                        color: #424242;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 24px;
                        margin-left: 70px;
                        align-items: center;
                    }
    
                    .day-mark{
                        display: flex;
                        width: 88px;
                        padding-top: 24px;

                        .form-check-input{
                            margin: 0px;
                        }

                        .form-check-input:checked{
                            background-color: #001CFF;
                            border-color: #001CFF;
                        }

                        .day-mark-day{
                            margin-left: 12px;
                            color: #212121;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }

                    .add-time{
                        display: flex;
                        width: fit-content;
                        height: 24px;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        padding-top: 30px;

                        .add-time-symbol{
                            margin-left: 4px;
                        }
                    }
    
                    .times{
                        display: flex;
                        flex-direction: column;
                        width: fit-content;
                        width: 100%;
                        // justify-content: center;
                        position: relative;
                        padding-top: 16px;
                        align-items: center;

                        .time-element{
                            height: 33px;
                            display: flex;
                            margin-bottom: 8px;

                            .error-icon-display{
                                display: flex;
                                align-items: center;
                                margin-right: 8px;
                            }

                            .delete-time{
                                align-items: center;
                                display: flex;
                                margin-left: 10px;
                                cursor: pointer;
                            }

                            .time-divider{
                                align-items: center;
                                display: flex;
                                margin-left: 4px;
                                margin-right: 4px;
                            }
                            
                            .choose-time{
                                height: 100%;
                                align-items: center;
                                display: flex;

                                .input-group{
                                    margin-bottom: 0px !important;
                                    height: 32px;
                                    width: 134px;

                                    .form-control{
                                        max-width: 140px;
                                        width: 100%;
                                        height: 32px;
                                    }

                                    .form-select{
                                        max-width: 120px;
                                        height: 32px;
                                    }
                                }
                            }
                            
                        }
                    }
                }
            } 
        }  

        .modal-footer{
            margin-top: 36px;

            .navigation-buttons{
                width: auto;
                button{
                    margin-right: 8px;
                    width: 168px;
                    height: 32px;
                    font-size: 13px;
                }

                .btn-outline-primary{
                    border-color: #001CFF;
                    color: #001CFF;
                    &:hover{
                        background-color: #001CFF;
                        border-color: #001CFF;
                        color: white;
                    }
                }
            }

            .niche-create-error{
                color: red;
            }
        }
    }
}

/* <input
type="number"
className="form-control"
aria-label="Text input with dropdown button"
placeholder={timeElement.end}
onChange={(e) =>
handleTimeInput(
    e.target.value,
    dayIndex,
    timeIndex,
    'end'
)
}
/>
<select
className="form-select form-select-sm"
aria-label=".form-select-sm example"
>
<option selected>--</option>
<option value="1">AM</option>
<option value="2">PM</option>
</select> */