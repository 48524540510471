@import url(https://fonts.googleapis.com/css?family=Montserrat);

.container{
    font-family: "Montserrat";

    .header-large{
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 32px;
        margin-top: 28px;
    }

    .onboard-photo{
        background-color: #D9D9D9;
        height: 224px;
        margin-bottom: 24px;
        width: auto;
    }

    .onboard-text{
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        margin-bottom: 72px;
    }

    .nav-buttons{
        display: inline-flex;
        margin-bottom: 32px;

        .next-button{
            .btn.btn-primary{
                background-color: #001CFF;
                border-color: #001CFF;
                width: 224px;

                &:hover{
                    background-color: #0015BB;
                }
            }
        }
    }
}