.profile-settings-container {
  display: block;
  margin-top: 24px;
  .profile-settings-block {
    display: flex;
    padding: 12px 16px;
    max-width: 716px;
    width: 100%;
    height: 48px;
    background: white;
    border-radius: 8px;
    position: relative;

    .block-text {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    .block-toggle {
      position: absolute;
      right: 16px;
    }
  }

  #email-notifications {
    border: 1px solid #e0e0e0;
  }

  #hide-profile {
    background: #fafafa;
    border: 0px;
    margin-top: 8px;
  }

  .save-settings {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;

    .btn.btn-primary {
      background-color: #001cff;
      border-color: #001cff;
      max-width: 112px;
      width: 100%;
    }
  }
}
