.navbar {
  width: 100%;
  height: 48px;
  background-color: #f5faff;

  .container-fluid {
    justify-content: normal;
    display: block !important;
  }

  .logo {
    font-family: 'Museo Slab';
    font-weight: 400;
    font-size: 24px;
    color: #9e9e9e;
    border-right: solid;
    border-color: #eeeeee;
    text-align: right;
    min-width: 185px;
    justify-content: flex-end;
    display: flex;

    .header-logo-navbar {
      background-image: url('../../Assets/JPGs/wt-logo-horizontal.png');
      background-size: 150px;
      width: 150px;
      height: 28px;
      background-repeat: no-repeat;

      &:hover {
        cursor: pointer;
      }

      svg {
        height: 32px;
        width: 32px;
        margin-right: 4px;
      }
    }
  }

  .expert-text {
    @media (max-width: 784px) {
      display: none;
    }

    text-align: left;
    font-family: 'Montserrat';
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin-left: 24px;
    margin-top: 4px;
  }

  .cancel-button {
    .btn.btn-outline-dark {
      font-family: 'Montserrat';
    }
    margin-right: 8px;
    margin-top: 2px;
    min-width: 100px;
  }
}
