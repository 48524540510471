.personal-info-wrapper{
    max-width: 464px;
    font-family: 'Montserrat';
    margin: auto;

    .header-section{
        display: block;
        overflow: auto;
        
        .header-text{
            float: left;
            font-size: 24px;
            line-height: 24px;
        }

        .required-fields{
            float: right;
            span{
                color: red;
            }

            color: #424242;
            font-size: 12px;
            line-height: 24px;
        }
    }

    .section-description{
        font-size: 13px;
        line-height: 25px;
        color: #616161;
        text-align: justify;
        margin-top: 10px;
        margin-bottom: 32px;
    }

    .info-inputs{

        .form-label{
            float: left;
        }

        span{
            margin-left:4px
        }

        .modal-dialog{

            .modal-title{
                font-size: 20px;
                font-weight: 700;
            }

            .modal-header{
                border-bottom: none;
            }
    
            .modal-body{
                .modal-body-text{
                    font-size: 13px;
                    color: #757575;
                    border-bottom: 0px;
                }
    
                .form-control{
                    &.invalid{
                        border-color: #F44336;
                    }
                }
    
                .invalid-feedback{
                    display: flex;
                }
            }

            .modal-footer{
                display: block;
                border-top: none;
                .btn.btn-outline-primary{
                    border-color: #001CFF;
                    width: 192px;
                }
            }
            

        }
    }

    .phone-number{

        .form-label{
            display: flex;
        }

        margin-bottom: 40px;
        display: grid;

        .phone-number-verify{
            display: flex;
    
            .form-control{
                margin-right: 8px;
            }

            .btn.btn-outline-primary{
                height: 48px;
            }
        }

        .valid-feedback{
            display: flex;
        }
        
    }
}

hr{
    max-width: 704px;
    color: #E0E0E0 !important;
    margin: auto !important;
    margin-bottom: 24px !important;
}

.navigation-buttons{
    display: flex;
    margin: auto;
    width: 100%;
    margin-bottom: 10px;

    max-width: 464px;

    .btn{
        width: 224px;
    }

    .btn.btn-outline-primary.btn-sm{
        border-color: #001CFF;
        width: 224px;
        margin-left: 16px;

        &:hover{
            background-color: #001CFF;
            border-color: #001CFF;
            color: white;
        }
    
    }

    .btn.btn-outline-dark{
        width: 224px;
    }
}

