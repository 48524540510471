.faq-container {
  padding: 16px 0px;
  position: relative;
  min-height: 80px;
  .collapse-faq {
    height: 48px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px 8px;

    .collapse-header-chevron {
      position: absolute;
      right: 8px;
      svg {
        transform: rotate(90deg);
      }
    }
  }

  svg {
    height: 24px;
    width: 24px;
    margin-right: 4px;
  }
}
