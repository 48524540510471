.search-results-container {
  .container {
    .search-and-sort {
      border-bottom: 1px solid #e0e0e0;
      align-items: center;
      margin-bottom: 16px;
      display: flex;
      position: relative;

      @media (max-width: 508px) {
        display: block;
      }

      .small-search {
        max-width: 380px;
        width: 100%;
        margin-right: auto;

        span {
          cursor: pointer;
        }
      }

      .sort-menu {
        width: 202px;
        height: 32px;
        cursor: pointer;
        width: fit-content;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
