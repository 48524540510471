@import url(https://fonts.googleapis.com/css?family=Montserrat);

.modal-dialog {
  &.modal-sm {
    // max-width: 540px !important;
    max-width: 540px;
  }

  .modal-content {
    color: black;
    .modal-header {
      .niche-header-dot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 4px;

        &.appointment {
          background: #2196f3;
        }

        &.request {
          background: #fbc02d;
        }

        &.past {
          background: #4caf50;
        }
      }

      .modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #000000;
      }

      .appointment-time {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #000000;
        margin-left: 4px;
      }

      .appointment-status {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        position: absolute;
        right: 64px;

        &.appointment {
          color: #2196f3;
        }

        &.request {
          color: #fbc02d;
        }

        &.past {
          color: #4caf50;
        }
      }
    }

    .modal-body {
      .popout-info {
        display: block;
        .popout-row {
          display: flex;
          height: 80px;
          margin-bottom: 16px;

          .popout-row-title {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: black;
            width: 100%;
            max-width: 90px;
          }

          .popout-row-info {
            display: block;

            .popout-row-text {
              display: flex;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              align-items: center;
              margin-bottom: 8px;

              .popout-row-svg {
                margin-right: 4px;
              }
            }

            .popout-row-description {
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #757575;
              white-space: pre-line;
              text-align: initial;
            }
          }
        }
      }
    }

    .modal-footer {
      display: block;

      .footer-actions {
        display: flex;

        .footer-tooltip {
          width: 100%;
          max-width: 90px;
        }

        .footer-options {
          display: block;
          height: 60px;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;

          .footer-options-descriptions {
            margin-bottom: 8px;
          }

          .footer-options-links {
            display: flex;

            .footer-options-link-text {
              margin-left: 4px;
              margin-right: 4px;
            }

            .footer-options-link {
              text-decoration: underline;
              color: #001cff;
              cursor: pointer;
            }
          }
        }
      }

      &.popout {
        margin-top: 0px;
        .accept-button {
          .btn.btn-primary.btn-sm {
            background-color: #001cff;
            border-color: #001cff;
            max-width: 448px;
            width: 100%;
          }
        }
      }
    }
  }
}
