.custom-toolbar {
  display: block;

  .toolbar-view-toggle {
    border-width: 1px 0px;
    border-style: solid;
    border-color: #e0e0e0;
    padding: 16px;
    .btn-group {
      max-width: 684px;
      width: 100%;
    }
  }

  .view-navigation {
    display: flex;
    height: 64px;
    padding: 16px;
    border-bottom: solid 1px #e0e0e0;
    position: relative;
    .view-navigation-date {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      display: flex;
    }

    .view-navigation-toggle{
        display: flex;
        position: absolute;
        right: 16px;

        #today-button{
            margin-left: 8px;
            margin-right: 8px;
        }
    }
  }
}
