.day-event-container {
  height: 100%;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  &.appointment {
    background-color: #e3f2fd;
    border: solid 1px #2196f3;
  }

  &.request {
    background-color: #fffbee;
    border: solid 1px #fbc02d;
  }

  &.past {
    background-color: #e8f5e9;
    border: solid 1px #4caf50;
  }

  .event-info {
    font-size: 11px;

    .event-title {
      color: black;
      padding-bottom: 4px;
    }

    .event-time {
      color: #757575;
    }
  }

  .event-options {
    display: flex;
    align-items: center;
    width: fit-content;
    position: absolute;
    right: 0;
    font-size: 12px;
    color: black;
    margin-right: 8px;

    .event-action {
      margin-right: 8px;
      margin-left: 8px;
      font-size: 12px;
      color: #001cff;
      text-decoration: underline;
      cursor: pointer;

      @media (max-width: 580px) {
        display: none;
      }
    }

    .event-action-text-filler {
      @media (max-width: 580px) {
        display: none;
      }
    }

    .btn.btn-primary.btn-sm {
      background-color: #001cff;
      border-color: #001cff;
      max-width: 448px;
      width: 100%;
      padding: 0px 12px;
      max-width: 96px;
      width: 100%;
      height: 22px;
    }

    .event-status {
      margin-right: 12px;
      margin-left: 10px;
      text-align: center;
      &.accepted {
        color: #001cff;
      }

      &.complete {
        color: #4caf50;
      }
    }
  }
}
