@import url(https://fonts.googleapis.com/css?family=Montserrat);

.page-background {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#0abde4, #7404c8);
  padding: 45px;
  .header-section-homepage-2 {
    max-width: 1500px;
    margin: 0 auto;
    .heading-text {
      margin-bottom: 16px;
      font-family: 'Montserrat';

      .welcome-text {
        margin-top: 64px;
        font-size: 48px;
        color: white;
        font-weight: 700;
      }
    }

    .youtube-video {
      width: 100%;
      position: relative;
      padding-bottom: 65.25%;
      padding-top: 25px;
      height: 0;
      margin: 0 auto;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .google-form {
      .google-form-text {
        margin-top: 64px;
        font-size: 32px;
        color: white;
        font-weight: 700;
      }
    }
  }
}

.header-section-homepage {
  height: 480px;
  background-image: url('../../Assets/JPGs/homepage-hero-image.jpg');
  padding: 24px;
  .heading-text {
    margin-bottom: 16px;
    font-family: 'Montserrat';

    .welcome-text {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 16px;
    }

    .find-experts-text {
      font-weight: 700;
      font-size: 40px;
    }
  }

  hr {
    max-width: 872px;
    width: 100%;
    border: 1px solid #e0e0e0;
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .anyone-expert {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    .anyone-expert-text {
      font-family: 'Montserrat';
      color: black;
    }
    .anyone-expert-money {
      color: #757575;
    }
    .anyone-expert-button {
      margin-top: 16px;
      .btn-primary {
        color: black;
        background: linear-gradient(90deg, #00ffb4 0%, #0ed7ff 100%);
        border: 2px solid #000000;
        border-radius: 4px;

        .button-contents {
          display: flex;
          align-items: center;
          max-width: 180px;
          width: 100%;
          justify-content: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          span {
            margin-right: 4px;
          }
        }
      }
    }
  }
}

.informational-cards {
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  // width: 872px;
  width: 100%;
  margin: auto;
  margin-top: -42px;

  @media (max-width: 900px) {
    display: none;
  }

  .informational-card {
    cursor: pointer;
    text-decoration: none !important;
    color: black;
    width: 280px;
    height: 264px;
    background: #ffffff;
    border: 2px solid #e0e0e0;
    border-radius: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    padding: 16px;

    .informational-card-header-text {
      color: #000000;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 4px;
    }

    .informational-card-support-text {
      color: #9e9e9e;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
    }

    .informational-card-image {
      width: 248px;
      height: 128px;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    #browse-com {
      background-image: url('../../Assets/JPGs/3d-browse-com.jpg');
    }
    #how-wt-works {
      background-image: url('../../Assets/JPGs/3d-how-wt-works.jpg');
    }
    #discover-more {
      background-image: url('../../Assets/JPGs/3d-discover-more.jpg');
    }

    .learn-more-button {
      button {
        width: 100%;
      }
      .btn-outline-primary {
        border-color: #001cff;
        color: #001cff;
        &:hover {
          background-color: #001cff;
          border-color: #001cff;
          color: white;
        }
      }
    }
  }
}

.popular-niches {
  background-color: #e3f2fd;
  width: 100%;
  margin-top: 160px;
  padding-top: 1px;
}

.comming-soon {
  margin-top: 96px;
  margin-bottom: 28px;
  font-family: 'Montserrat';
  font-size: 24px;
}
