.month-event-container{
    display: flex;
    flex-wrap: wrap;
    height: 20px;
    margin-top: 4px;
    position: relative;
    .month-event{
        flex-shrink: 0;
        color: black;
        font-size: 12px;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        text-align: center;
        &.appointment{
            background-color: #E3F2FD;
            border: solid 1px #2196F3;
        }

        &.request{
            background-color: #FFFBEE;
            border: solid 1px #FBC02D;
        }

        &.past{
            background-color: #E8F5E9;
            border: solid 1px #4CAF50;
        }
    }

    .availablity-background{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: beige;
    }
}
