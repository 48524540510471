.photo-and-description {
  display: flex;
  min-height: 200px;
  border-bottom: 1px solid #e0e0e0;

  @media (max-width: 508px) {
    display: contents;
  }

  .modal-expert-photo {
    justify-content: center;
    display: flex;
    img {
      border-radius: 50%;
      width: 136px;
      height: 136px;
      border: solid;
    }
  }

  .photo-divider-line {
    margin-left: -14px;

    @media (max-width: 550px) {
        display: none;
      }
  }

  .modal-expert-description-username {
    font-style: normal;
    font-family: 'Montserrat';
    padding: 0px 16px;

    .modal-expert-username {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-decoration-line: underline;
      color: #000000;
    }
    .modal-expert-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: -0.02em;
      color: #616161;
    }
  }
}

.expert-description-tags-title {
  padding: 16px 0px;
  border-bottom: 1px solid #e0e0e0;
  .expert-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  .expert-tags {
    display: flex;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 12px;
    flex-wrap: wrap;

    .niche-content-tag {
      border: solid 1px black;
      border-radius: 4px;
      height: 32px;
      width: fit-content;
      padding-top: 2px;
      padding-left: 8px;
      padding-right: 8px;
      font-weight: 400;
      line-height: 24px;
      font-size: 13px;
      margin-right: 4px;
      margin-bottom: 8px;
    }
  }

  .expert-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #616161;
  }
}

.call-information-verify {
  height: 212px;
  padding: 16px 0px;
  border-bottom: 1px solid #e0e0e0;
  .call-information {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #616161;
    margin-bottom: 12px;
  }

  button {
    font-size: 15px;
    width: 116px;
  }

  .phone-verify {
    display: flex;
    margin-bottom: 8px;
    input {
      margin-right: 4px;
    }
  }

  .code-verify {
    display: flex;
    input {
      margin-right: 4px;
    }
  }

  .verification-message {
    margin-top: -2px;

    .valid-feedback-verify {
      color: green;
      font-size: 13px;
    }
  }
}

.footerCustom {
  margin-top: 0px !important;

  &.modal-footer {
    justify-content: flex-start;
  }

  .expert-modal-custom-footer {
    display: contents;
    width: 100%;

    .price-details-price-warning {
      display: block;
      margin-right: auto;
      .price-details {
        display: flex;

        .price-detail {
          font-size: 14px;
          line-height: 24px;
          margin-right: 16px;
        }
      }

      .price-warning {
        color: #9e9e9e;
        size: 14px;
        line-height: 20px;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  button {
    height: 48px;
    min-width: 128px;
    justify-content: center;
    align-items: center;
    background-color: #90caf9;
    border: 1px solid #90caf9;
    border-radius: 4px;
    color: #0d47a1;
    font-size: 14px;
  }
}
