.rbc-agenda-view {
  background-color: #fafafa;
  border-top: solid 1px #e0e0e0;
  min-height: 272px !important;
  padding: 16px;
  .rbc-agenda-table {
    border: none !important;

    .rbc-header {
      display: none !important;
    }
  }

  .rbc-agenda-content {
    .rbc-agenda-event-cell {
      display: none !important;
    }

    .rbc-agenda-time-cell {
      border: none !important;
      text-transform: none !important;
    }

    tr {
      border: none !important;
    }
  }
}

.custom-agenda-event-wrapper {
  max-width: 468px;
  height: 100%;
  width: 100%;
  max-height: 240px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;

  .custom-agenda-event-header {
    display: flex;
    height: 40px;
    align-items: center;
    padding: 20px 8px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    position: relative;

    &.request {
      background: #fffbee;
    }

    .dot-status {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 4px;

      &.appointment {
        background: #2196f3;
      }

      &.request {
        background: #fbc02d;
      }

      &.past {
        background: #4caf50;
      }
    }

    .status-block {
      font-weight: 500;
      font-size: 12px;
      display: flex;
      align-items: center;
      position: absolute;
      right: 8px;
      height: 24px;
      width: 68px;
      justify-content: center;
      border-radius: 4px;

      &.appointment {
        background-color: #2196f3;
        color: white;
      }

      &.request {
        background-color: #fbc02d;
      }

      &.past {
        background-color: #4caf50;
        color: white;
      }
    }
  }

  .custom-agenda-event-body {
    display: flex;
    height: 124px;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
    position: relative;

    .appointment-with-section {
      display: block;

      .appointment-with-section-header {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }

      .appointment-with-section-user {
        display: flex;
        margin-top: 8px;

        .appointment-with-section-avatar {
          height: 32px;
          width: 32px;
          margin-right: 4px;
        }

        .appointment-with-section-name {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .niche-info-section {
      display: block;
      position: absolute;
      right: 16px;
      width: 165px;
      .niche-info-section-header {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        white-space: pre-line;
        text-align: left;
      }

      .niche-info-section-body {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        white-space: pre-line;
        text-align: left;
      }
    }
  }

  .custom-agenda-event-footer {
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;

    .completed-appointment-footer {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #616161;
    }

    .footer-actions {
      display: flex;
      width: 100%;
      padding: 0px 8px;
      .footer-tooltip {
        width: 100%;
        max-width: 90px;
      }

      .footer-options {
        display: flex;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        flex-direction: row-reverse;
        width: 100%;
        .footer-options-descriptions {
          margin-bottom: 8px;
        }

        .footer-options-links {
          display: flex;
          margin-right: 8px;

          .footer-options-link-text {
            margin-left: 4px;
            margin-right: 4px;
          }

          .footer-options-link {
            text-decoration: underline;
            color: #001cff;
            cursor: pointer;
          }
        }
      }

      .accept-button {
        max-width: 178px;
        width: 100%;
        margin-right: 8px;
        .btn.btn-primary.btn-sm {
          background-color: #001cff;
          border-color: #001cff;
          width: 100%;
        }
      }

      .decline-button {
        max-width: 178px;
        width: 100%;
        margin-right: 8px;
        .btn.btn-outline-dark.btn-sm {
          width: 100%;
        }
      }

      .request-new-time-dropdown {
        width: 32px;
        .btn.btn-primary.btn-sm {
          background-color: #001cff;
          border-color: #001cff;
          width: 100%;
        }
      }
    }

    margin-top: 0px;
  }
}
