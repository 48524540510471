@import url(https://fonts.googleapis.com/css?family=Montserrat);

.spinner-border{
    margin-top: 48px;
}

.calendar-tab-container{
    font-family: "Montserrat";
    margin-bottom: 16px;
    .calendar-tab-header{
        margin-top: 24px;
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -1%;
        text-align: left;

        span{
            margin-left: 4px;
        }
    }

    .call-information-sections{
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 24px;

        .call-information-section{
            max-width: 182px;
            height: 80px;
            width: 100%;
            border: solid 1px #E0E0E0;
            border-radius: 8px;
            display: block;
            padding: 14px;

            .call-information-number{
                font-weight: 700;
                font-size: 24px;
                height: 24px;
                line-height: 24px;
                margin-bottom: 8px;
            }

            .call-information-text{
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
            }
        }
    } 
    
    .availability-section{
        height: 64px;
        display: flex;
        padding: 16px;
        border-top: solid 1px black;
        // border-bottom: solid 1px black;
        // margin-bottom: 16px;

        .availability-section-header{
            font-size: 13px;
            font-weight: 700;
            height: 32px;
            display: flex;
            align-items: center;
            margin-right: 32px;
        }

        .set-availability-section{
            width: 100%;

            .btn.btn-primary.btn-sm{
                background-color: #001CFF;
                border-color: #001CFF;
                max-width: 448px;
                width: 100%;
            }
        }
    }

}