.personal-info-wrapper {
  max-width: 464px;
  font-family: 'Montserrat';
  margin: auto;

  .header-section {
    display: block;
    overflow: auto;

    .header-text {
      float: left;
      font-size: 24px;
      line-height: 24px;
    }

    .required-fields {
      float: right;
      span {
        color: red;
      }

      color: #424242;
      font-size: 12px;
      line-height: 24px;
    }
  }

  .section-description {
    font-size: 13px;
    line-height: 25px;
    color: #616161;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 32px;
  }

  .photo-upload-container {
    width: 320px;
    height: 264px;
    background-color: #f5f5f5;
    margin: auto;
    padding: 28px;
    padding-top: 60px;
    border: dashed #bdbdbd;
    border-radius: 8px;

    .upload-instructions-text {
      font-size: 13px;
      line-height: 20px;
      color: #757575;

      p {
        margin-bottom: 0px;
      }
    }

    .form-control {
      margin-top: 16px;
    }

    .file-type-text {
      margin-top: 18px;
      color: #bdbdbd;
      font-size: 12px;
    }
  }

  .avatar-checkbox {
    margin-top: 16px;
    display: inline-flex;
    margin-bottom: 24px;

    .avatar-text {
      margin-left: 8px;
      line-height: 24px;
      color: #757575;
      font-size: 13px;
    }
  }

  .photo-preview {
    margin-bottom: 16px;

    .photo-preview-header {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .photo-preview-photo{
        img{
            border-radius: 16px;
        }
    }
  }
}

hr {
  max-width: 704px;
  color: #e0e0e0 !important;
  margin: auto !important;
  margin-bottom: 24px !important;
}

.navigation-buttons {
  display: flex;
  margin: auto;
  width: 100%;
  margin-bottom: 10px;

  max-width: 464px;

  .btn {
    width: 224px;
  }

  .btn.btn-outline-primary.btn-sm {
    border-color: #001cff;
    width: 224px;
    margin-left: 16px;

    &:hover {
      background-color: #001cff;
      border-color: #001cff;
      color: white;
    }
  }

  .btn.btn-outline-dark {
    width: 224px;
  }
}
