.expert-card-container {
  position: relative;
  width: 280px;
  height: 400px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  cursor: pointer;

  .expert-card-header {
    padding: 10px;
    height: 212px;
    background: #fafafa;
    align-items: center;
    display: block;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    align-items: baseline;

    .expert-card-header-icon {
      margin-right: 8px;
    }

    .expert-card-header-username {
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #000000;
      text-align: initial;
      margin-bottom: 8px;
    }

    .expert-card-photo-section {
      // height: 160px;
      width: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;

      .expert-card-photo {
        height: 160px;
        width: 160px;
        border-radius: 16px;

        img {
          border-radius: 16px;
          height: 160px;
          width: 160px;
        }
      }
    }
  }

  .expert-card-content {
    padding: 16px;

    .expert-card-content-header {
      width: 248px;
      height: 20px;
      left: 16px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: flex-start;
    }

    .expert-card-content-description {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #757575;
      margin-top: 8px;
      text-align: initial;
      height: 42px;
      overflow-y: hidden;
    }

    .expert-card-content-price {
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      color: #000000;
      margin-top: 12px;
      margin-bottom: 12px;
      text-align: left;
      border-top: solid 1px #e0e0e0;

      strong {
        color: #757575;
      }
    }

    .expert-card-content-reviews {
      display: flex;
      align-items: flex-start;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-top: 8px;

      .expert-card-content-star {
        width: 20px;
        height: 20px;
        margin-right: 4px;
        svg {
          fill: gold;
        }
      }

      .expert-card-content-rating {
        height: 20px;
        margin-right: 4px;
      }

      .expert-card-content-reviews-number {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: #001cff;
      }
    }

    .expert-card-content-tags {
      display: flex;
      margin-top: 16px;

      .expert-card-content-tag {
        margin-right: 4px;
        width: fit-content;
        padding: 0px 8px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #757575;
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #757575;
        display: flex;
        align-items: center;
      }
    }
  }

  .expert-card-footer {
    position: absolute;
    bottom: 0;
    // border-top: 1px solid #e0e0e0;
    background: #ffffff;
    height: 56px;
    width: 100%;
    display: flex;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 0px 16px;
    align-items: center;

    .btn.btn-outline-primary {
      border-color: #001cff;
      color: #001cff;
      width: 100%;
      // max-width: 120px;

      &:hover {
        background-color: #001cff;
        border-color: #001cff;
        color: white;
      }
    }
  }
}
