/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.niche-tab {
  display: block;

  .no-niches-header {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -1%;
    text-align: left;

    span {
      margin-left: 4px;
    }
  }

  .no-niches-container {
    max-width: 560px;
    width: 100%;
    height: 296px;
    border: solid 1px;
    border-color: #bdbdbd;
    border-radius: 8px;

    .no-niches-container-contents {
      margin: 18% auto;

      .no-niche-text {
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
      }
    }
    .add-niche-modal {
      .modal {
        .modal-dialog {
          max-width: 640px;

          .modal-content {
            .modal-body {
              font-size: 16px;
              line-height: 27px;
              font-weight: 400;
              color: #000000;
              text-align: left;

              .modal-form-section {
                margin-top: 24px;

                .form-label {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  margin-bottom: 16px;
                }

                .input-group {
                  margin-bottom: 32px;
                  border-left: none;
                  .input-group-text {
                    background-color: white;
                  }
                }

                .niche-content-tags {
                  display: flex;
                  width: 100%;
                  flex-wrap: wrap;

                  .tag-with-x {
                    display: flex;
                    .niche-content-tag {
                      border: solid;
                      height: 32px;
                      width: 100%;
                      padding-left: 8px;
                      padding-right: 8px;
                      font-weight: 400;
                      line-height: 24px;
                      font-size: 13px;
                      margin-right: 4px;
                      margin-left: 4px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 4px;
                      border: solid;
                      border-color: #9e9e9e;
                      background-color: #fafafa;

                      .circle-x-remove {
                        display: block;
                        cursor: pointer;
                        margin-left: 10px;
                        margin-bottom: 2px;
                      }
                    }
                  }
                }

                .add-tag {
                  cursor: pointer;
                  display: flex;

                  button {
                    margin-left: 8px;
                  }
                  .add-niche-text {
                    color: #616161;
                    font-size: 16px;
                    line-height: 24px;
                    margin-left: 16px;
                  }

                  .input-group {
                    margin-bottom: 32px;
                    border-left: none;
                    display: contents;
                    .input-group-text {
                      background-color: white;
                      padding: 0px;
                      padding-top: 2px;
                      padding-bottom: 2px;
                    }

                    .form-control {
                      border-left: none;
                    }
                  }
                }

                .payrate-input {
                  display: flex;
                  .payrate-amount {
                    max-width: 256px;
                    width: 100%;
                    .input-group {
                      border-left: none;
                      .input-group-text {
                        background-color: white;
                        font-weight: 700;
                        font-size: 16px;
                      }

                      .form-control {
                        border-left: none;
                        text-align: right;
                      }
                    }
                  }

                  .per-text {
                    height: 48px;
                    width: 72px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                  }

                  .payrate-time-schedule {
                    max-width: 158px;
                    width: 100%;
                  }
                }
              }
            }

            .modal-footer {
              .navigation-buttons {
                width: auto;
                button {
                  margin-right: 8px;
                  width: 168px;
                }

                .btn-outline-primary {
                  border-color: #001cff;
                  color: #001cff;
                  &:hover {
                    background-color: #001cff;
                    border-color: #001cff;
                    color: white;
                  }
                }
              }

              .niche-create-error {
                color: red;
              }
            }
          }
        }
      }
    }
  }
}

.niches-container {
  margin-top: 24px;
  max-width: 560px;
  width: 100%;
  height: 296px;
  border: solid 1px;
  border-color: #bdbdbd;
  border-radius: 8px;
  margin-bottom: 24px;

  .niche-header-section {
    height: 48px;
    padding: 12px;
    display: block;
    width: 100%;

    .niche-header-text {
      height: 24px;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      float: left;
    }

    .dropdown {
      .niche-header-button {
        float: right;
        cursor: pointer;
      }

      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;

      .dropdown-menu {
        li {
          cursor: pointer;
          display: flex;

          a {
            span {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  .niche-content-section {
    display: block;
    .niche-content-description-tags {
      min-height: 157px;
      width: 100%;
      height: 100%;
      padding: 0px 16px 0px 16px;

      .niche-content-descrition {
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 16px;
        min-height: 92px;
      }

      .niche-content-tags {
        display: flex;
        width: 100%;
        overflow-x: auto;
        .niche-content-tag {
          border: solid 1px #bdbdbd;
          color: #bdbdbd;
          border-radius: 18px;
          height: 32px;
          width: fit-content;
          padding-top: 2px;
          padding-left: 8px;
          padding-right: 8px;
          font-weight: 400;
          line-height: 24px;
          font-size: 13px;
          margin-right: 4px;
          margin-bottom: 8px;
        }
      }
    }

    .niche-content-rate-reviews {
      width: 100%;
      max-height: 88px;
      height: 100%;
      display: inline-flex;
      justify-content: space-between;
      border-top: 1px solid #000000;
      .rate-per-time {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 187px;

        .rate-per-time-text {
          color: #4caf50;
          font-size: 14px;
          line-height: 28px;
          font-weight: 700;
        }
      }

      .number-of-calls {
        border-right: solid 1px;
        border-left: solid 1px;
        width: 100%;
        max-width: 187px;
      }

      .niche-reviews {
        width: 100%;
        max-width: 187px;
      }

      hr {
        color: #bdbdbd !important;
        margin-bottom: 0px !important;
      }

      .number-of-calls {
        height: 88px;
        display: flex;
        justify-content: center;
        align-items: center;

        .number-of-calls-text {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .niche-reviews {
        height: 92px;
        padding-top: 12px;
        .niche-review-stars {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .niche-number-reviews {
          font-size: 14px;
          margin-top: 12px;
        }
      }
    }
  }

  .no-niches-container-contents {
    margin: 18% auto;

    .no-niche-text {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
    }

    .add-niche-button {
      margin-top: 24px;

      .btn.btn-primary {
        background-color: #001cff;
        max-width: 224px;
        width: 100%;
      }
    }
  }
}

.add-niche-container {
  display: block;
  cursor: pointer;
  text-align: left;

  .add-niche-text {
    color: #616161;
    font-size: 16px;
    line-height: 24px;
  }
}

.modal {
  #confirmDeleteModal {
    max-width: 395px;

    .modal-footer {
      button {
        width: 150px;
      }
      .btn-outline-primary {
        border-color: #001cff;
        color: #001cff;
        &:hover {
          background-color: #001cff;
          border-color: #001cff;
          color: white;
        }
      }

      .btn-primary {
        border-color: #001cff;
        background-color: #001cff;
        color: white;
        &:hover {
          background-color: white;
          border-color: #001cff;
          color: #001cff;
        }
      }
    }
  }

  .modal-dialog {
    max-width: 640px;

    .modal-content {
      .modal-body {
        font-size: 16px;
        line-height: 27px;
        font-weight: 400;
        color: #000000;
        text-align: left;

        .modal-form-section {
          margin-top: 24px;

          .form-label {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
          }

          .input-group {
            margin-bottom: 32px;
            border-left: none;
            .input-group-text {
              background-color: white;
            }
          }

          .niche-content-tags {
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            .tag-with-x {
              display: flex;
              .niche-content-tag {
                border: solid;
                height: 32px;
                width: 100%;
                padding-left: 8px;
                padding-right: 8px;
                font-weight: 400;
                line-height: 24px;
                font-size: 13px;
                margin-right: 4px;
                margin-left: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                border: solid;
                border-color: #9e9e9e;
                background-color: #fafafa;

                .circle-x-remove {
                  display: block;
                  cursor: pointer;
                  margin-left: 10px;
                  margin-bottom: 2px;
                }
              }
            }
          }

          .add-tag {
            cursor: pointer;
            display: flex;

            button {
              margin-left: 8px;
            }
            .add-niche-text {
              color: #616161;
              font-size: 16px;
              line-height: 24px;
              margin-left: 16px;
            }

            .input-group {
              margin-bottom: 32px;
              border-left: none;
              display: contents;
              .input-group-text {
                background-color: white;
                padding: 0px;
                padding-top: 2px;
                padding-bottom: 2px;
              }

              .form-control {
                border-left: none;
              }
            }
          }

          .payrate-input {
            display: flex;
            .payrate-amount {
              max-width: 256px;
              width: 100%;
              .input-group {
                border-left: none;
                .input-group-text {
                  background-color: white;
                  font-weight: 700;
                  font-size: 16px;
                }

                .form-control {
                  border-left: none;
                  text-align: right;
                }
              }
            }

            .per-text {
              height: 48px;
              width: 72px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 16px;
              line-height: 24px;
              font-weight: 400;
            }

            .payrate-time-schedule {
              max-width: 158px;
              width: 100%;
            }
          }
        }
      }

      .modal-footer {
        .navigation-buttons {
          width: auto;
          button {
            margin-right: 8px;
            width: 168px;
          }

          .btn-outline-primary {
            border-color: #001cff;
            color: #001cff;
            &:hover {
              background-color: #001cff;
              border-color: #001cff;
              color: white;
            }
          }
        }

        .niche-create-error {
          color: red;
        }
      }
    }
  }
}
