@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

:root {
  --line-border-fill: #4caf50;
  --line-border-progress-fill: #212121;
  --line-border-empty: #e0e0e0;
}

.container {
  margin-top: 15px;
  text-align: center;

  .progress-container::before {
    content: '';
    background: var(--line-border-empty);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 4px;
    width: 100%;
    z-index: -1;

    @media (max-width: 580px) {
      margin-top: 12px;
    }
  }

  .progress-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 30px;
    max-width: 768px;
    margin: auto;
    margin-bottom: 48px;

    .progress-b {
      background: var(--line-border-fill);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 4px;
      width: 0%;
      z-index: -1;
      // transition: 0.4s ease;

      @media (max-width: 580px) {
        margin-top: 11px;
      }
    }

    .circle-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 25px;
      width: 50px;
      font-family: 'Montserrat';
      font-size: 12px;
      font-weight: 400;

      .onboard-arrow {
        position: absolute;
        margin-top: -22px;
      }

      .section-text {
        width: max-content;
        margin-top: 8px;

        @media (max-width: 580px) {
          display: none;
        }
      }

      .circle {
        background: #fff;
        color: #999;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid var(--line-border-empty);
        transition: 0.4s ease;
      }

      .circle.active {
        border-color: var(--line-border-progress-fill);
      }

      .circle.complete {
        border-color: var(--line-border-fill);
      }

      .section-number {
        font-family: 'Montserrat';
        font-size: 24px;
        font-weight: 700;
        color: #212121;

        &.inactive {
          color: #e0e0e0;
        }
      }
    }
  }
}

.btn:active {
  transform: scale(0.98);
}

.btn:focus {
  outline: 0;
}

.btn:disabled {
  background-color: var(--line-border-empty);
  cursor: not-allowed;
}
