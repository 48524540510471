.profilePicture {
  display: flex;
  flex-direction: row;
  align-items: end;

  img {
    width: 144px;
    height: 144px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.userDetails {
  display: flex;
  flex-direction: column;

  margin-top: 16px;
  margin-bottom: 28px;

  // input:first-of-type {
  //   margin-bottom: 8px;
  // }

  input {
    margin-bottom: 8px;
  }
}

.phoneNumber {
  margin-top: 12px;
  margin-bottom: 32px;
}

.aboutYou {
  margin-top: 12px;
}

.footerCustom {
  display: flex !important;

  button {
    width: 144px;
    height: 32px;
    line-height: 1px;
  }
}

.credentials {
  margin-top: 16px;
  margin-bottom: 28px;
  input {
    margin: 10px 0 10px 0;
  }
}

.bio {
  margin-top: 16px;
}
