.stripe-setup-container{

    max-width: 560px;
    width: 100%;
    height: 296px;
    border: solid;
    border-color: #BDBDBD;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .no-stripe-container-contents{
        margin: 18% auto;

        .stripe-img{
            margin-bottom: 16px;
        }

        .no-stripe-text{
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
        }

        .add-stripe-button{
            margin-top: 24px;

            .btn.btn-primary{
                background-color: #001CFF;
                max-width: 152px;
                width: 100%;
                font-size: 14px;
            }
        }

    }
    
}