.personal-info-wrapper {
  max-width: 464px;
  font-family: 'Montserrat';
  margin: auto;

  .header-section {
    display: block;
    overflow: auto;

    .header-text {
      float: left;
      font-size: 24px;
      line-height: 24px;
    }

    .required-fields {
      float: right;
      span {
        color: red;
      }

      color: #424242;
      font-size: 12px;
      line-height: 24px;
    }
  }

  .section-description {
    font-size: 13px;
    line-height: 25px;
    color: #616161;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 32px;
  }

  .personal-link {
    .form-inline {
      display: flex;
      margin-top: 12px;
      margin-bottom: 12px;

      .invalid-feedback {
        display: flex;
      }

      form {
        width: 100%;
        margin-right: 8px;
      }
    }

    .form-control {
      margin-right: 8px;
    }

    .button-section {
      margin-bottom: 12px;
    }
  }
}

hr {
  max-width: 704px;
  color: #e0e0e0 !important;
  margin: auto !important;
  margin-bottom: 24px !important;
}

.navigation-buttons {
  display: flex;
  margin: auto;
  width: 100%;
  margin-bottom: 10px;

  max-width: 464px;

  .btn {
    width: 224px;
  }

  .btn.btn-outline-primary.btn-sm {
    border-color: #001cff;
    width: 224px;
    margin-left: 16px;

    &:hover {
      background-color: #001cff;
      border-color: #001cff;
      color: white;
    }
  }

  .btn.btn-outline-dark {
    width: 224px;
  }
}
